$background: #2b2b2b;
$text: #a9b7c6;
$keyword: #cc7832;
$number: #6897bb;
$identifier: #9876aa;
$docComment: #629755;
$string: #6a8759;
$function: #ffc66d;
$comment: #808080;
$htmlTag: #e8bf6a;
$htmlAttributeValue: #a5c261;
$xmlEntityReference: #6d9cbe;

.highlight, pre code {
  background: $background;
  color: $text;
  overflow-x: auto;

  .nx {
    color: $text;
  }

  .k, // Keyword
  .kd, // Keyword.Definition
  .cp, // Comment.Preproc
  .nt // Name.Tag
  {
    color: $keyword;
  }

  .c, // Comment
  .c1, // Comment.Single
  .cm // Comment.Multiline
  {
    color: $comment;
  }

  .nv // Name.Variable
  {
    color: $identifier;
  }

  .na // Name.Attribute
  {
    color: $function;
  }

  .no, // Name.Constant
  .gh // Generic.Header
  {
    color: $identifier;
    font-style: italic;
  }

  .mi // Literal.Number.Integer
  {
    color: $number;
  }

  .sd // Literal.String.Doc
  {
    color: $docComment;
  }

  .s, // Literal.String
  .s1, // Literal.String.Single
  .s2 // Literal.String.Double
  {
    color: $string;
  }

  .nf, // Name.Function
  .fm {
    color: $function;
  }

  &.html {
    .p, .nt {
      font-weight: normal;
      color: $htmlTag;
    }

    .na {
      color: $text;
    }

    .s {
      color: $htmlAttributeValue;
    }
  }

  &.xml {
    .nt, .cp {
      color: $htmlTag;
    }

    .ni {
      color: $xmlEntityReference;
    }
  }
}
