@import "~@fontsource/open-sans/latin-400.css";
@import "~@fontsource/open-sans/latin-700.css";
$web-font-path: false;

@import "~bootswatch/dist/simplex/variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/simplex/bootswatch";

@import "callout";

@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "pygments-darcula";

h1, h2, h3, h4, h5, h6 {
  &:not(:first-child) {
    margin-top: 1.5em !important;
  }
}

footer a i.bi {
  opacity: 0.5;
  margin: 0 5px;

  &:hover {
    opacity: 1;
  }
}

blockquote {
  padding-left: 0.75em;
  border-left: 5px solid #eee;
}

blockquote.testimonial {
  padding-left: 0;
  border-left: 0;

  display: inline-block;
  quotes: "“" "”" "‘" "’";

  &::before {
    content: open-quote;
  }
  &::after {
    content: close-quote;
  }
}

.embed-responsive {
  margin-bottom: 1rem;
}

img {
  max-width: 100%;
  height: auto;
}

pre code {
  display: block;
  padding: 2em;
}

li {
  p {
    margin-bottom: 0;
  }

  pre {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }
}

.social {
  .bi-twitter-x {
    color: #000000;
  }

  .bi-mastodon {
    color: #563ACC;
  }

  .bi-linkedin {
    color: #0082ca;
  }

  .bi-facebook {
    color: #3b5998;
  }

  .bi-reddit {
    color: #ff4500;
  }

  .bi-whatsapp {
    color: #25d366;
  }

  .bi-telegram {
    color: #0088cc;
  }

  .bi:hover {
    filter: brightness(80%);
  }
}

#clients img {
  opacity: 0.9;

  &:hover {
    opacity: 1;
  }

  transition: opacity 0.2s;
}

.feature-icon {
  width: 3rem;
  height: 3rem;
}
